
import { useI18n } from 'vue-i18n'
import {
  IActivationFields,
  ISmaPriTemplateConfig,
  SmaPriTemplateFieldItem,
} from 'smartbarcode-web-core/src/utils/types'
import { Options, Vue } from 'vue-class-component'
import { PropSync, Watch } from 'vue-property-decorator'
import { uploadProjectAttachedFile, getProjectAttachedFile } from '@/utils/api'
import { openMessage } from '@/utils/utils'
import { UploadFile } from 'element-plus/lib/el-upload/src/upload.type'
import errorHandler from '@/utils/errorHandler'
import { ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums/index'
export const reservedFieldNames = ['trackingNum', 'sbUrl']
@Options({
  emits: ['update:smapriTemplateConfig'],
  name: 'SmaPriFormatBlock',
})
export default class SmaPriConfigBlock extends Vue {
  @PropSync('activationFields', { type: Object }) barcodeActivationFields!: IActivationFields
  @PropSync('smapriTemplateConfig', { type: Object }) templateConfig!: ISmaPriTemplateConfig
  rules = {
    buttonLabel: [{ required: this.isEnabledSmaPri || false, trigger: 'blur' }],
  }

  reservedNameFlags = [] as boolean[]

  candidateTemplateFieldsMap = new Map<string, string>()
  availableFieldType = [
    ECustomFieldType.TEXT,
    ECustomFieldType.DATE,
    ECustomFieldType.NUMBER,
    ECustomFieldType.SINGLE_SELECT,
    ECustomFieldType.MULTI_SELECT,
    ECustomFieldType.PHONE_NUMBER,
    ECustomFieldType.EMAIL,
  ]

  get projectCode() {
    return this.$store.state.project.projectDetail.mainInfo?.code ?? ''
  }

  get isEditMode() {
    return !!this.$store.state.project.projectDetail.mainInfo?.id
  }

  get isEnabled() {
    return this.templateConfig?.isEnabledSmaPri ?? false
  }

  set isEnabled(value: boolean) {
    this.templateConfig.isEnabledSmaPri = value
  }

  onToggleEnabledSmaPri(val: boolean) {
    if (val === true && this.isEditMode === false) {
      this.templateConfig.isEnabledSmaPri = false
      openMessage(this.$t('barcode_type.smapri.error.failed_enabling'), 'warning')
      return false
    }
  }

  async downloadFormat() {
    try {
      if (this.templateConfig.templateFilePath) {
        this.templateConfig.fileUrl = await getProjectAttachedFile(this.templateConfig.templateFilePath || '')
        setTimeout(() => {
          this.$refs.btnDownloadTemplate.click()
        }, 0)
      }
    } catch (err) {
      errorHandler(err as string | Record<string, string[]> | Error)
    }
  }

  changeUploadFile(uploadFile: UploadFile) {
    this.templateConfig.fileName = uploadFile.name
    this.templateConfig.existFile = uploadFile
    this.templateConfig.isDisableUploadFile = false
  }

  async submitUpload() {
    const formData = new FormData()
    formData.append('file', this.templateConfig.existFile.raw)
    formData.append('projectCode', this.projectCode)
    formData.append('isPublicRead', 'true')

    try {
      const uploadedFile = await uploadProjectAttachedFile(formData)
      this.templateConfig.templateFilePath = uploadedFile
      this.templateConfig.existFile = {} as UploadFile
      openMessage(this.$t('save_successful'), 'success')
    } catch (err) {
      errorHandler(err as string | Record<string, string[]> | Error)
    }
  }

  get currentTemplateConfig() {
    return this.templateConfig
  }

  get currentTemplateFields() {
    const templateConfig = this.templateConfig
    return templateConfig.templateFields
  }

  get candidateTemplateFieldKeys() {
    return [...this.candidateTemplateFieldsMap.keys()]
  }

  get currentCandidateTemplateFieldKeys() {
    const candidateKeys = this.candidateTemplateFieldKeys
    return candidateKeys.filter((key) => !this.templateConfig.templateFields?.map((item) => item.key).includes(key))
  }

  removeTemplateFieldItem(removeIndex: number) {
    this.templateConfig.templateFields.splice(removeIndex, 1)
    this.reservedNameFlags.splice(removeIndex, 1)
  }

  get canAddTemplateFieldItem() {
    return (
      this.templateConfig.templateFields === undefined ||
      (this.currentCandidateTemplateFieldKeys && this.currentCandidateTemplateFieldKeys.length > 0)
    )
  }

  shouldShowAddButton(index: number) {
    return this.templateConfig.templateFields.length === index + 1 && this.canAddTemplateFieldItem
  }

  addTemplateFieldItem() {
    const addingKey = this.currentCandidateTemplateFieldKeys[0]
    if (!addingKey) {
      return
    }

    const addingFieldItem = {
      key: addingKey,
      label: this.candidateTemplateFieldsMap.get(addingKey) ?? '',
      fieldName: addingKey,
    } as SmaPriTemplateFieldItem

    if (this.templateConfig.templateFields === undefined) {
      this.templateConfig.templateFields = [addingFieldItem]
      this.reservedNameFlags = [this.isConflictWithReservedFieldName(addingKey)]
    } else {
      this.templateConfig.templateFields.push(addingFieldItem)
      this.reservedNameFlags.push(this.isConflictWithReservedFieldName(addingKey))
    }
  }

  @Watch('templateConfig.templateFields', { deep: true })
  onFieldNameChanged() {
    this.reservedNameFlags = this.templateConfig?.templateFields?.map((field) =>
      this.isConflictWithReservedFieldName(field.key)
    )
  }

  getTemplateFieldItemByKey(key: string) {
    const item = this.candidateTemplateFieldsMap.get(key)
    return {
      key: key,
      label: item,
    }
  }

  canSelectTempalteKey(key: string) {
    return (
      this.currentCandidateTemplateFieldKeys.includes(key) &&
      !this.templateConfig.templateFields.some((field) => field.key === key)
    )
  }

  onSelectChange(key: string, index: number) {
    const label = this.candidateTemplateFieldsMap.get(key) ?? ''
    this.templateConfig.templateFields[index].key = key
    this.templateConfig.templateFields[index].label = label
    this.templateConfig.templateFields[index].fieldName = key
    this.reservedNameFlags[index] = this.isConflictWithReservedFieldName(key)
  }

  mounted() {
    this.templateConfig = {
      ...this.templateConfig,
      isEnabledSmaPri: this.templateConfig.isEnabledSmaPri ?? false,
      templateFields: this.templateConfig.templateFields ?? [],
    } as ISmaPriTemplateConfig
    this.reservedNameFlags = this.templateConfig?.templateFields?.map((field) =>
      this.isConflictWithReservedFieldName(field.fieldName)
    )
  }

  updated() {
    const { t } = useI18n()
    this.candidateTemplateFieldsMap = new Map<string, string>()
    this.candidateTemplateFieldsMap.set('sbUrl', t('barcode_type.smapri.smartBarcodeUrl'))
    const trackingNumberLabel =
      this.barcodeActivationFields.trackingNumber.label || t('barcode_type.smapri.trackingNumberDefaultLabel')
    this.candidateTemplateFieldsMap.set('trackingNum', trackingNumberLabel)
    for (const [key, field] of Object.entries(this.barcodeActivationFields.customFields)) {
      if (this.availableFieldType.includes(field.fieldType)) this.candidateTemplateFieldsMap.set(key, field.label)
    }
  }

  isConflictWithReservedFieldName(text: string) {
    return reservedFieldNames.includes(text)
  }
}
